@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  color: #101828;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.1;
}

h2 {
  color: #101828;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
}

h3 {
  color: #101828;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
}

h4 {
  color: #101828;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
}

h5 {
  color: #101828;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

h6 {
  color: #101828;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: #000;
}

.form_input {
  @apply w-full px-4 py-3 text-base leading-tight transition-all duration-300 placeholder:text-neutral-500 bg-neutral-900 border rounded border-neutral-700 focus:border-primary-500 focus:ring-1 focus:ring-primary-500 focus-visible:outline-none;
}

.form_input:focus-visible {
  outline: none;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-out;
}

.button-loader {
  width: 16px;
  height: 16px;
  border: 3px solid currentColor;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Michroma";
  src: url("/src/fonts/Michroma-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sauce Sans";
  src: url("/src/fonts/OpenSauceSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sauce Sans";
  src: url("/src/fonts/OpenSauceSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sauce Sans";
  src: url("/src/fonts/OpenSauceSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sauce Sans";
  src: url("/src/fonts/OpenSauceSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}